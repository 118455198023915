import React, { useEffect, useState } from 'react'
import DataGrids from '../../table/DataGrid';
import "../reports/reports.css"
import Leftnav from '../leftNav/Leftnav';
import Loader from '../Loader';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Navbar from '../navbar';
import axios from 'axios';
import { GETALL_MUSEUM, URL_ALLEVENTS, URL_GETALLORDER_BY_EVENTID, URL_GETALLORGANISOR_FOR_DROPDOWN, URL_GETALLUSER, URL_GETEVENT_WISE_REPORT, URL_GETREPORTS, URL_GETUNIQUEEVENT, URL_GET_CLIENT_WISE_REPORT, URL_GET_ORDER_TICKETID, URL_GET_REPORT_BY_CUSTOMER } from '../../api/constants';
import Alert from '../alert/alert';
import tick from "../../images/tiick-unscreen.gif"
// import { isHtmlElement } from 'react-router-dom/dist/dom';

function Reports() {

  const [alert, setalert] = useState(false)
  const [alertmsg, setalertmsg] = useState("")
  const [filteredData, setFilteredData] = useState([]);
  const [tableData, setTableData] = useState([])
  const [loader, setloader] = useState(false)
  const [fromdate, setfromdate] = useState("")
  const [todate, settodate] = useState("")
  const [clienttable, setclienttable] = useState(false)
  const [datetable, setdatetable] = useState(false)
  const [clienttableData, setclienttableData] = useState([])
  const [visitor, setvisitor] = useState("")
  const [museum, setMuseum] = useState("")
  const [exhibitor, setexhibitor] = useState("")
  const [alluser, setalluser] = useState("")
  const [useralltable, setallusertable] = useState(false)
  const [organisertable, setorganisertable] = useState(false)
  const [exhibitortable, setexhibitortable] = useState(false)
  const [OrganiserData, setOrganiserData] = useState([])

  const [filtertype, setfiltertype] = useState("")
  const [clientfilter, setclientfilter] = useState(false)
  const [eventfilter, seteventfilter] = useState(false)
  const [eventwisefilter, seteventwisefilter] = useState(false)
  const [datefilter, setdatefilter] = useState(false)
  const [dropdown, setdropdown] = useState([])
  const [allevents, setallevents] = useState([])
  const [visitorDropdown, setvisitorDropdown] = useState([])
  const [exhibitorDropdown, setexhibitorDropdown] = useState([])
  const [eventdropdown, seteventdropdown] = useState([])
  const [eventFIlterval, seteventfilterval] = useState("")
  const [eventwiseFIlterval, seteventwiseFIlterval] = useState("")
  const [filterclientval, setfilterclientval] = useState("")
  const [filtervendorval, setfiltervendorval] = useState("")
  const [filterexhibitorval, setfilterexhibitorval] = useState("")
  const [filterAlluser, setfilteralluser] = useState("")
  const [filterNationality, setfilterNationality] = useState("")
  const [alluserdropdown, setalluserdropdown] = useState([])
  const [usertable, setusertable] = useState([])
  const [eventTableshow, seteventTableshow] = useState(false)
  const [museumTableshow, setMuseumTable] = useState(false)
  const [eventwiseTableshow, seteventwiseTableshow] = useState(false)
  const [museumTableData, setMuseumtabledata] = useState([])
  const [EventTableData, setEventTableData] = useState([])
  const [EventwiseTableData, setEventwiseTableData] = useState([])
  const [ticket, setticket] = useState([])
  const [booth, setbooth] = useState([])
  const [addon, setaddon] = useState([])
  const [OrganisertableSibibling, setOrganisertableSibibling] = useState([])
  const [organerticketFilter, setorganerticketFilter] = useState([])
  const [allticketUniqueEvent, setallticketUniqueEvent] = useState([])
  const [ifView, setifView] = useState(true)



  setTimeout(() => {
    setalert(false)
  }, 3000);

  useEffect(() => {

    var config = {
      method: 'get',
      url: URL_GETALLUSER,
      headers: {}
    };
    axios(config)
      .then(function (response) {

        setalluserdropdown(response.data)

        setloader(false)
      })
      .catch(function (error) {
        console.log(error);
      });
    // get all events ----------------------------------------------


    var data = JSON.stringify({
      "status": "ALL"
    });

    var config = {
      method: 'post',
      url: URL_ALLEVENTS,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config).then(function (response) {

      setallevents(response.data.data);

    })
      .catch(function (error) {
        console.log("oops" + error);
      });



    // -----------------------------------------------------------------------------

    if (filtertype == "event") {
      seteventfilter(true)
      seteventwisefilter(false)
      setclientfilter(false)
      setdatefilter(false)
      setvisitor(false)
      setexhibitor(false)
      setalluser(false)
      setorganisertable(false)
      setexhibitortable(false)
      setMuseum(false)
    }
    else if (filtertype == "eventwise") {
      seteventfilter(false)
      seteventwisefilter(true)
      setclientfilter(false)
      setdatefilter(false)
      setvisitor(false)
      setexhibitor(false)
      setalluser(false)
      setorganisertable(false)
      setexhibitortable(false)
      setMuseum(false)
    }
    else if (filtertype == "museum") {
      setMuseum(true)
      seteventfilter(false)
      seteventwisefilter(false)
      setclientfilter(false)
      setdatefilter(false)
      setvisitor(false)
      setexhibitor(false)
      setalluser(false)
      setorganisertable(false)
      setexhibitortable(false)
    }
    else if (filtertype == "client") {
      setMuseum(false)
      seteventfilter(false)
      seteventwisefilter(false)
      setclientfilter(true)
      setdatefilter(false)
      setvisitor(false)
      setalluser(false)
      setexhibitor(false)
      seteventTableshow(false)
      setexhibitortable(false)
    }
    else if (filtertype == "date") {
      setMuseum(false)
      seteventfilter(false)
      seteventwisefilter(false)
      setclientfilter(false)
      setdatefilter(true)
      setvisitor(false)
      setalluser(false)
      setexhibitor(false)
      setorganisertable(false)
      setexhibitortable(false)
      seteventTableshow(false)
    }
    else if (filtertype == "Exhibitor") {
      setMuseum(false)
      seteventfilter(false)
      seteventwisefilter(false)
      setclientfilter(false)
      setdatefilter(false)
      setvisitor(false)
      setexhibitor(true)
      setalluser(false)
      seteventTableshow(false)
      setexhibitortable(true)
    }
    else if (filtertype == "visitor") {
      setMuseum(false)
      seteventfilter(false)
      seteventwisefilter(false)
      setclientfilter(false)
      setdatefilter(false)
      setvisitor(true)
      setalluser(false)
      setexhibitor(false)
      setorganisertable(false)
      seteventTableshow(false)
      setexhibitortable(false)
    }
    else if (filtertype == "user") {
      setMuseum(false)
      seteventfilter(false)
      seteventwisefilter(false)
      setclientfilter(false)
      setdatefilter(false)
      setvisitor(false)
      setalluser(true)
      setorganisertable(false)
      setexhibitor(false)
      seteventTableshow(false)
      setexhibitortable(false)
    }



  }, [filtertype])




  useEffect(() => {
    axios.get(URL_GETALLORGANISOR_FOR_DROPDOWN).then((response) => {

      setdropdown(response.data)
    })

    axios.post(URL_ALLEVENTS, { "status": "ALL" }).then((response) => {

      seteventdropdown(response.data.data)
    })





    // load all sold tickets 

    var config = {
      method: 'get',
      url: URL_GET_ORDER_TICKETID,
      headers: {}
    };

    axios(config)
      .then(function (response) {

        setOrganisertableSibibling(response.data.data)
      })
      .catch(function (error) {
        console.log(error);
      });



  }, [])

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  // -------------------------------------------------


  const UsertableColumns = [
    // { field: 'id', headerName: 'ID' },
    { field: 'firstName', headerName: 'First Name', width: 130 },
    { field: 'lastName', headerName: 'Last Name', width: 130 },
    { field: 'mail', headerName: 'Email', width: 180 },
    { field: 'password', headerName: 'Password', width: 180 },
    { field: 'userName', headerName: 'Mobile', width: 150 },
    { field: 'userType', headerName: 'User Type', width: 150 },
    { field: 'strCompanyName', headerName: 'Company Name', width: 150 },


  ]

  const organisereventsCol = [

    { field: 'name', headerName: 'Event Name', width: 150 },
    { field: 'venueLandMark', headerName: 'Venue LandMark', width: 100 },
    { field: 'venueAddress', headerName: 'Venue Address', width: 120 },
    { field: 'venueType', headerName: 'Venue Type', width: 150 },
    { field: 'summary', headerName: 'Summary', width: 150 },

    {
      field: "startDate",
      headerName: "Start Date",
      width: 120,
      disableClickEventBubbling: true,
      renderCell: (params) => {

        return (
          params.row.exhibitionStartDate.split("T")[0].split("-").reverse().join().replace(",", "-").replace(",", "-")
        );
      }
    },
    {
      field: "endDate",
      headerName: "End Date",
      width: 120,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          params.row.exhibitionEndDate.split("T")[0].split("-").reverse().join().replace(",", "-").replace(",", "-")
        );
      }
    },
    {
      field: "Tickets",
      headerName: "Tickets",
      width: 130,
      disableClickEventBubbling: true,
      renderCell: (params) => {

        return (
          <Button onClick={e => viewTickt(params)} variant="contained" color="primary">
            {" "}
            View Ticket
          </Button>
        );
      }
    }
  ]

  var eventwisetblColumn = [
    // { field: 'id', headerName: 'ID' },

    { field: 'name', headerName: 'Name' },
    { field: 'surname', headerName: 'Surname', width: 150 },
    { field: 'gender', headerName: 'Gender', width: 150 },
    { field: 'email', headerName: 'Email', width: 150 },
    { field: 'jobTitle', headerName: 'Job Title', width: 150 },
    { field: 'companyOrganisation', headerName: 'Company / Organisation', width: 150 },
    { field: 'workAddress', headerName: 'Work Address', width: 150 },
    { field: 'workPhone', headerName: 'Work Phone', width: 150 },
    { field: 'homeAddress', headerName: 'Home Address', width: 150 },
    { field: 'homePhone', headerName: 'Home Phone', width: 150 },
    { field: 'website', headerName: 'Website', width: 150 },
    { field: 'cellPhone', headerName: 'Cell Phone', width: 150 },

    { field: 'blog', headerName: 'Blog', width: 150 },
    // {
    //   field: "View Event",
    //   headerName: "QR Code",
    //   sortable: false,
    //   width: 130,
    //   disableClickEventBubbling: true,
    //   renderCell: (params) => {
    //     return (
    //       <Button onClick={e=>viewQR(params.row)} variant="contained" color="primary">
    //         {" "}
    //         Qr Code
    //       </Button>
    //     );
    //   },
    // }
  ]


  var totalTicket = [];
  const viewTickt = (item) => {
    setifView(false)

    setorganerticketFilter(item.id)

    for (var i = 0; i < OrganisertableSibibling.length; i++) {

      if (OrganisertableSibibling[i].eventRowpos === item.row.rowpos) {
        totalTicket.push(OrganisertableSibibling[i])
      }
    }
    const unique = [...new Map(totalTicket.map((m) => [m.billNumber, m])).values()];
    setallticketUniqueEvent(unique)
  }

  const EventTableCol = [
    // { field: 'id', headerName: 'ID' },
    { field: "orgName", headerName: "Organiser Name", width: 200 },
    { field: "name", headerName: " Event Name", width: 200 },
    { field: "category", headerName: "Category", width: 180 },
    {
      field: "startDate",
      headerName: "Start Date",
      width: 180,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return params.row.exhibitionStartDate
          .split("T")[0]
          .split("-")
          .reverse()
          .join()
          .replace(",", "-")
          .replace(",", "-");
      },
    },
    {
      field: "edate",
      headerName: "End Date",
      width: 180,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return params.row.exhibitionEndDate
          .split("T")[0]
          .split("-")
          .reverse()
          .join()
          .replace(",", "-")
          .replace(",", "-");
      },
    }
  ];


  const formatMuseumData = (data) => {
    return data.map((item, index) => ({
      rowpos: item.rowpos || index,
      institutioN_TYPE: item.institutioN_TYPE,
      grouP_NAME: item.grouP_NAME,
      inout: item.inout,
      remarks: item.remarks,
      pcQuantity: item.pcQuantity,
      stafF_CNT: item.stafF_CNT,
      adulT_CNT: item.adulT_CNT,
      instituteType: item.instituteType,
      strtotalPurchase: item.strtotalPurchase,
      studenT_CNT: item.studenT_CNT,
      billNumber: item.billNumber,
      chilD_CNT: item.chilD_CNT,
      item: item.item,
      icode: item.icode,
      'user.mobile': item.user.mobile,
      'user.mail': item.user.mail,
      billDate: item.billDate,
      remark: item.remark,
      nationality: item.nationality
    }));
  };

  const museumTableCol = [


    // { field: 'id', headerName: 'ID' },
    { field: "remarks", headerName: "NAME", width: 200 },
    { field: "inout", headerName: "SCAN", width: 200 },
    { field: "item", headerName: "ITEM NAME", width: 200 },
    { field: "icode", headerName: "ICODE", width: 200 },
    { field: "strtotalPurchase", headerName: "TOTAL PURCHASE", width: 200 },
    { field: "billNumber", headerName: "BILL NUMBER", width: 200 },
    { field: "user.mobile", headerName: "CONTACT NUMBER", width: 200 },
    { field: "user.mail", headerName: "EMAIL", width: 180 },
    { field: "nationality", headerName: "NATIONALITY", width: 180 },
    { field: "instituteType", headerName: "GROUP TYPE", width: 180 },
    { field: "stafF_CNT", headerName: "STAFF COUNT", width: 180 },
    { field: "chilD_CNT", headerName: "CHILD COUNT", width: 200 },
    { field: "studenT_CNT", headerName: "STUDENT COUNT / GROUP MEMBER", width: 300 },
    { field: "adulT_CNT", headerName: "ADULT COUNT / INCHARGE ", width: 300 },
    { field: "institutioN_TYPE", headerName: "INSTITUTION TYPE ", width: 200 },
    { field: "grouP_NAME", headerName: "INSTITUTION NAME ", width: 200 },
    {
      field: "billDate",
      headerName: "BILL DATE",
      width: 180,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return params.row.billDate
          .split("T")[0]
          .split("-")
          .reverse()
          .join()
          .replace(",", "-")
          .replace(",", "-");
      },
    },

  ];

  // ----------------------------------------

  var organisertblSibilingTickets = [
    { field: 'strCustomerName', headerName: 'Customer Name', width: 130 },
    { field: 'orgName', headerName: 'Organiser Name', width: 130 },
    { field: 'ticketType', headerName: 'Ticket Type', width: 130 },
    { field: 'strTicketID', headerName: 'Ticket Id', width: 180 },
    { field: 'billNumber', headerName: 'Order Number', width: 150 },
    {
      field: "billDate",
      headerName: "Order Date",
      width: 180,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return params.row.billDate
          .split("T")[0]
          .split("-")
          .reverse()
          .join()
          .replace(",", "-")
          .replace(",", "-");
      },
    },

    { field: 'pcQuantity', headerName: 'Quantity', width: 180 },
    {
      field: "balanceAmount",
      headerName: "Amount",
      width: 180,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return params.row.balanceAmount * params.row.pcQuantity;
      },
    },
    {
      field: "icode",
      headerName: "Scanned",
      width: 100,
      disableClickEventBubbling: true,
      renderCell: (params) => {

        return params.row.icode === "IN" ? <img style={{ height: "80px", marginLeft: "-30px" }} src={tick} /> : <img style={{ height: "30px", width: "50px" }} src="https://th.bing.com/th/id/OIP.pYx4niZBEXZvzCYXPDQTLQHaHa?w=173&h=180&c=7&r=0&o=5&pid=1.7" />
      }
    },
    {
      field: "strInOut",
      headerName: "Booked from",
      width: 220,
      disableClickEventBubbling: true,
      renderCell: (params) => {

        return params.row.strInOut === "ONSPOT" ? params.row.strInOut : "APP"
      }
    },
    { field: 'remark', headerName: 'Status', width: 150 }

  ]

  // -------------------------------------------------

  const columns = [
    // { field: 'id', headerName: 'ID' },
    { field: 'name', headerName: 'Event Name', width: 100 },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 180,
      disableClickEventBubbling: true,
      renderCell: (params) => {

        return (
          params.row.createdAt.replace("T", " ")
        );
      }
    },
    { field: 'exhibitionStartDate', headerName: 'startDate', width: 110 },
    { field: 'exhibitionEndDate', headerName: 'endDate', width: 110 },
    {
      field: "entryType",
      headerName: "Entry Type",
      width: 180,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          params.row.entryType.slice(1).split("").reverse().slice(1).reverse()
        );
      }
    },
    { field: 'orgName', headerName: 'Company Name', width: 150 },
    { field: 'status', headerName: 'status', width: 100 },
    { field: 'exhibitionStartTime', headerName: 'startTime', width: 100 },
    { field: 'exhibitionEndTime', headerName: 'endTime', width: 100 },
    {
      field: "media_list",
      headerName: "NO. of Media",
      width: 100,
      disableClickEventBubbling: true,
      renderCell: (params) => {

        return (
          params.row.media_list.length
        );
      }
    },
    {
      field: "ticket_list",
      headerName: "NO. of Tickets",
      width: 100,
      disableClickEventBubbling: true,
      renderCell: (params) => {

        return (
          params.row.ticket_list.length
        );
      }
    },
    {
      field: "booth_list",
      headerName: "NO. of booth list",
      width: 100,
      disableClickEventBubbling: true,
      renderCell: (params) => {

        return (
          params.row.booth_list.length
        );
      }
    },
    {
      field: "addon_list",
      headerName: "NO. of addon list ",
      width: 100,
      disableClickEventBubbling: true,
      renderCell: (params) => {

        return (
          params.row.addon_list.length
        );
      }
    }
  ]

  const clientcolumn = [
    { field: 'strCustomerName', headerName: 'client name', width: 180 },
    { field: 'eventName', headerName: 'Event Name', width: 180 },
    // { field: 'iSortOrder', headerName: 'Sort Order', width: 160 },
    // { field: 'strCompanyName', headerName: 'Company Name', width: 160 },
    { field: 'balanceAmount', headerName: 'Balance Amount', width: 200 },
    { field: 'chNumber', headerName: 'Order Number', width: 180 },
    { field: 'ticketType', headerName: 'Ticket Type', width: 180 },
    { field: 'remark', headerName: 'Status', width: 180 },
  ]




  const getreports = () => {


    setifView(true)



    if (filtertype === "date") {
      seteventTableshow(false)
      seteventwiseTableshow(false)
      setdatetable(true)
      setMuseumTable(false)
      setclienttable(false)
      axios.post(URL_GETREPORTS, { "FromDate": fromdate, "ToDate": todate }).then((response) => {


        if (response.data.data === null) {
          setalert(true)
          setalertmsg(`OOPS ! No Data Found From ${fromdate} TO ${todate}`)
          setloader(true)
          setTimeout(() => {
            setloader(false)
          }, 5000);

        }
        else {
          setloader(false)
          setTableData(response.data.data)
        }

      })
    }
    else if (filtertype === "event") {

      setMuseumTable(false)
      setallusertable(false)
      setdatetable(false)
      setclienttable(false)
      setorganisertable(false)
      seteventwiseTableshow(false)
      //hit api to filter based on events

      var data = JSON.stringify({
        status: eventFIlterval,
      });

      var config = {
        method: "post",
        url: URL_ALLEVENTS,

        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config)
        .then(function (response) {

          seteventTableshow(true)
          setEventTableData(response.data.data)


        })
        .catch(function (error) {
          console.log(error);
        });


    }
    else if (filtertype === "eventwise") {
      setMuseumTable(false)
      setallusertable(false)
      setdatetable(false)
      setclienttable(false)
      setorganisertable(false)
      seteventTableshow(false)
      //hit api to filter based on selected event 
      seteventwiseTableshow(true)


      axios.post(URL_GETUNIQUEEVENT, { "ROWPOS": eventwiseFIlterval }).then((response) => {

        setEventwiseTableData(response.data.data[0].registration_list)

      })

    } else if (filtertype === "museum") {
      setallusertable(false)
      setdatetable(false)
      setMuseumTable(true)
      setclienttable(false)
      setorganisertable(false)
      seteventTableshow(false)
      //hit api to filter based on selected event 
      seteventwiseTableshow(false)
      axios.get(GETALL_MUSEUM).then((response) => {

        var museumData = []
        var inout;
        for (var i = 0; i < response.data.data.length; i++) {
          if (response.data.data[i].inout == 'IN') {
            response.data.data[i].inout = 'SCANNED'
          }
          else if (response.data.data[i].inout == 'OUT') {
            response.data.data[i].inout = 'NOT SCANNED'
          }


          museumData.push(response.data.data[i])

        }

        const formattedMuseumData = formatMuseumData(museumData);

        setMuseumtabledata(formattedMuseumData)
        setFilteredData(formattedMuseumData)
      }).catch((error) => {
        console.error("Error fetching museum data:", error);
      });
    }


    else if (filtertype === "client") {
      var Org = [];

      var arr1 = []
      var arr2 = []
      var arr3 = []
      var merge = []
      setMuseumTable(false)
      setallusertable(false)
      seteventTableshow(false)
      setclienttable(false)
      setdatetable(false)
      setorganisertable(true)
      seteventwiseTableshow(false)
      //hit api to filter based on clients
      var data = JSON.stringify({
        "status": "ALL"
      });

      var config = {
        method: 'post',
        url: URL_ALLEVENTS,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(config).then(function (response) {

        var mydata = response.data.data;


        for (var i = 0; i < mydata.length; i++) {
          if (mydata[i].orgMcode == filterclientval) {
            Org.push(mydata[i]);
          }
        }

        setOrganiserData(Org)

        setticket(Org[0].ticket_list)
        setbooth(Org[0].booth_list)
        setaddon(Org[0].addon_list)

        arr1.push(Org[0].ticket_list)
        arr2.push(Org[0].booth_list)
        arr3.push(Org[0].addon_list)

        var mergedArr = merge.concat(...arr1, ...arr2, ...arr3)


        // setOrganisertableSibibling(mergedArr)


        // setclienttableData(Org);

      })
        .catch(function (error) {
          console.log("oops" + error);
        });
    }

    // if exhibitor
    else if (filtertype === "Exhibitor") {
      setclienttable(true)
      setdatetable(false)
      setallusertable(false)
      setvisitor(false)
      setexhibitor(true)
      setMuseumTable(false)
      seteventwiseTableshow(false)
      //hit api to filter based on visitors
      var data = JSON.stringify({
        "MCODE": filterexhibitorval
      });

      var config = {
        method: 'post',
        url: URL_GET_REPORT_BY_CUSTOMER,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(config)
        .then(function (response) {

          if (response.data.data !== null) {
            setclienttableData(response.data.data)
          }
          else {
            setalert(true)
            setalertmsg("NO RESULT FOUND !!")
            setclienttableData([])

          }
        })
        .catch(function (error) {
          console.log(error);
        });



    }

    // -------

    else if (filtertype === "visitor") {
      setMuseumTable(false)
      setclienttable(true)
      setdatetable(false)
      setallusertable(false)
      setvisitor(true)
      seteventwiseTableshow(false)
      //hit api to filter based on visitors
      var data = JSON.stringify({
        "MCODE": filtervendorval
      });

      var config = {
        method: 'post',
        url: URL_GET_REPORT_BY_CUSTOMER,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(config)
        .then(function (response) {
          if (response.data.data !== null) {
            setclienttableData(response.data.data)
          }
          else {
            setalert(true)
            setalertmsg("NO RESULT FOUND !!")
            setclienttableData([])
          }
        })
        .catch(function (error) {
          console.log(error);
        });





    }
    else if (filtertype === "user") {
      var ThisUserdetail = [];
      setMuseumTable(false)
      seteventTableshow(false)
      setclienttable(false)
      setdatetable(false)
      setvisitor(false)
      seteventwiseTableshow(false)




      for (var i = 0; i < alluserdropdown.length; i++) {
        if (alluserdropdown[i].userType === filterAlluser) {
          ThisUserdetail.push(alluserdropdown[i])
        }
      }
      setusertable(ThisUserdetail)
      setallusertable(true)

    }
    else {
      setalert(true)
      setalertmsg("Please select filter to get Data !")
    }



    if (alert === true) {
      setalert(false)
    }

  }



  useEffect(() => {
    if (fromdate && todate) {
      const filtered = museumTableData.filter((item) => {
        const itemDate = new Date(item.billDate);
        return itemDate >= new Date(fromdate) && itemDate <= new Date(todate);
      });
      setFilteredData(filtered);
      console.log("Filtered Data:", filtered);
    } else {
      setFilteredData(museumTableData);
      console.log("Original Data:", museumTableData);
    }
  }, [fromdate, todate, museumTableData]);


  const closeifview = () => {
    setifView(true)
  }

  var visitors = [];
  var exhibitors = [];
  const handlesetfiltertype = (e) => {
    setfiltertype(e.target.value)

    document.querySelector('.pagenames').innerHTML = `order ${e.target.value}`
    if (e.target.value === "Exhibitor") {
      var config = {
        method: 'get',
        url: URL_GETALLUSER,
        headers: {}
      };
      axios(config)
        .then(function (response) {


          for (var i = 0; i < response.data.length; i++) {
            if (response.data[i].userType === 'EXHIBITOR') {
              exhibitors.push(response.data[i])
            }
          }

          setexhibitorDropdown(exhibitors)

        })
        .catch(function (error) {
          console.log(error);
        });
    }
    else if (e.target.value === "visitor") {
      var config = {
        method: 'get',
        url: URL_GETALLUSER,
        headers: {}
      };
      axios(config)
        .then(function (response) {


          for (var i = 0; i < response.data.length; i++) {
            if (response.data[i].userType === 'VISITOR') {
              visitors.push(response.data[i])
            }
          }

          setvisitorDropdown(visitors)

        })
        .catch(function (error) {
          console.log(error);
        });

    }
  }


  const color = "red"


  return (
    <>
      <Navbar />
      <Leftnav />
      {alert ? <Alert className="top animating" msg={alertmsg} color={color} /> : ""}
      <div className='main'>
        <center><h5 className="pagenames orgrank animatingLeft" >Order Report</h5></center>
        <br />
        <div className="">
          <form className="d-flex space" autoComplete="off">
            <div className="mb-3">
              <label className="form-label"> REPORT FILTER &nbsp;&nbsp; <ion-icon name="funnel-outline"></ion-icon> </label>
              <br />
              <select className='reportfilterselectopt' value={filtertype} onChange={(e) => handlesetfiltertype(e)}>
                <option value="">select &nbsp;</option>
                <option value="client">Organiser Report</option>
                <option value="visitor">Visitor Report</option>
                <option value="Exhibitor">Exhibitor Report</option>
                <option value="user">User Report</option>
                <option value="event">Event Report</option>
                <option value="museum">Museum Report</option>
                <option value="eventwise">Event wise Report</option>
                <option value="date">Date Wise Report</option>
              </select>

            </div>
            {
              datefilter ?
                <>
                  <div className="mb-3">
                    <label className="form-label">FROM DATE</label>
                    <input type="date" value={fromdate} onChange={(e) => setfromdate(e.target.value)} className="form-control small" />

                  </div>
                  <div className="mb-3">
                    <label className="form-label">TO DATE</label>
                    <input type="date" value={todate} onChange={(e) => settodate(e.target.value)} className="form-control small" />

                  </div>
                </> : ""
            }
            {
              visitor ?
                <>
                  <div className='mb-3'>
                    <label className="form-label">Select Visitor</label>
                    <br />
                    <select style={{ width: "200px" }} value={filtervendorval} onChange={(e) => setfiltervendorval(e.target.value)}>
                      <option value="">select</option>
                      {
                        visitorDropdown.map((item, i) => (
                          <option key={i} value={item.mcode}>{item.firstName}</option>
                        ))
                      }
                    </select>
                  </div>
                </>
                : ""
            }
            {
              exhibitor ?
                <>
                  <div className='mb-3'>
                    <label className="form-label">Select Exhibitor</label>
                    <br />
                    <select style={{ width: "200px" }} value={filterexhibitorval} onChange={(e) => setfilterexhibitorval(e.target.value)}>
                      <option value="">select</option>
                      {
                        exhibitorDropdown.map((item, i) => (
                          <option key={i} value={item.mcode}>{item.firstName}</option>
                        ))
                      }
                    </select>
                  </div>
                </>
                : ""
            }
            {
              alluser ?
                <>
                  <div className='mb-3'>
                    <label className="form-label">Select User</label>
                    <br />
                    <select style={{ width: "200px" }} value={filterAlluser} onChange={(e) => setfilteralluser(e.target.value)}>
                      <option value="">select User</option>
                      <option value="ORGANISER">ORGANISER</option>
                      <option value="VISITOR">VISITOR</option>
                      <option value="EXHIBITOR">EXHIBITOR</option>
                    </select>
                  </div>
                </>
                : ""
            }

            {
              museum ?
                <>
                  <div className="mb-3">
                    <label className="form-label">FROM DATE</label>
                    <input type="date" value={fromdate} onChange={(e) => setfromdate(e.target.value)} className="form-control small" />

                  </div>
                  <div className="mb-3">
                    <label className="form-label">TO DATE</label>
                    <input type="date" value={todate} onChange={(e) => settodate(e.target.value)} className="form-control small" />

                  </div>
                </>



                : ""
            }

            {
              clientfilter ?
                <>
                  <div className='mb-3'>
                    <label className="form-label">Select Organiser</label>
                    <br />
                    <select style={{ width: "200px" }} value={filterclientval} onChange={(e) => setfilterclientval(e.target.value)}>
                      <option value="">select</option>
                      {
                        dropdown.map((item, i) => (
                          <option key={i} value={item.orgMcode}>{item.orgName}</option>
                        ))
                      }
                    </select>
                  </div>
                </> : ""
            }
            {
              eventfilter ?
                <>
                  <div className='mb-3'>
                    <label className="form-label">Select Event Type</label>
                    <br />
                    <select style={{ width: "200px" }} value={eventFIlterval} onChange={(e) => seteventfilterval(e.target.value)}>
                      <option value="">select</option>
                      <option value="ALL">ALL</option>
                      <option value="APPROVED">APPROVED</option>
                      <option value="PENDING">UNAPPROVED</option>
                      <option value="DRAFT">DRAFT</option>
                    </select>
                  </div>
                </> : ""
            }
            {
              eventwisefilter ?
                <>
                  <div className='mb-3'>
                    <label className="form-label">Select Event</label>
                    <br />
                    <select style={{ width: "200px" }} value={eventwiseFIlterval} onChange={(e) => seteventwiseFIlterval(e.target.value)}>
                      <option value="">select</option>
                      {allevents.map((event, i) => (
                        <option value={event.rowpos}>{event.name}</option>
                      ))}


                    </select>
                  </div>
                </> : ""
            }
            <div className="mb-3">
              <Button
                className='animatingBottom'
                style={{
                  borderRadius: 5,
                  backgroundColor: "tomato",
                  padding: "6px 24px",
                  fontSize: "14px",
                  float: "right",
                  marginRight: "20px",
                  marginTop: "20px"
                }}
                variant="contained" onClick={getreports} >SEARCH</Button>

              {/* <label className="form-label">SEARCH</label>
            <input type="text" className="form-control small"  /> */}

            </div>
          </form>
        </div>
        {
          loader ? <div className='reportloader table'> <Loader /></div> :
            <div className='table dashtbl'>
              {
                datetable ?
                  <DataGrid
                    rows={tableData}
                    columns={columns}
                    pageSize={5}
                    getRowId={(row) => row.rowpos}
                    components={{
                      Toolbar: CustomToolbar,
                    }}
                    // checkboxSelection
                    onSelectionModelChange={(ids) => {
                      const selectedIDs = new Set(ids);
                      const selectedRows = tableData.filter((row) =>
                        selectedIDs.has(row.rowpos),

                      );

                      // setSelectedRows(selectedRows);
                      // console.log(selectedRows)
                      // setfirstname(selectedRows.mcode)

                    }}
                    {...tableData}
                  /> : ""
              }
              {
                clienttable ?

                  <DataGrid
                    rows={clienttableData}
                    columns={clientcolumn}
                    pageSize={5}
                    getRowId={(row) => row.chNumber}
                    components={{
                      Toolbar: CustomToolbar,
                    }}
                    // checkboxSelection
                    onSelectionModelChange={(ids) => {
                      const selectedIDs = new Set(ids);
                      const selectedRows = tableData.filter((row) =>
                        selectedIDs.has(row.chNumber),

                      );

                      // setSelectedRows(selectedRows);
                      // console.log(selectedRows)
                      // setfirstname(selectedRows.mcode)

                    }}
                    {...clienttableData}
                  /> : ""
              }
              {
                useralltable ?

                  <DataGrid
                    rows={usertable}
                    columns={UsertableColumns}

                    pageSize={5}
                    getRowId={(row) => row.mcode}
                    components={{
                      Toolbar: CustomToolbar,
                    }}
                    // checkboxSelection
                    onSelectionModelChange={(ids) => {
                      const selectedIDs = new Set(ids);
                      const selectedRows = tableData.filter((row) =>
                        selectedIDs.has(row.mcode),

                      );

                      // setSelectedRows(selectedRows);
                      // console.log(selectedRows)
                      // setfirstname(selectedRows.mcode)

                    }}
                    {...usertable}
                  /> : ""
              }
              {
                organisertable ?
                  <>
                    {
                      ifView ?
                        <DataGrid
                          rows={OrganiserData}
                          columns={organisereventsCol}
                          getRowId={(row) => row.rowpos}
                          components={{
                            Toolbar: CustomToolbar,
                          }}
                          pageSize={6}
                          // checkboxSelection
                          onSelectionModelChange={(ids) => {
                            const selectedIDs = new Set(ids);
                            const selectedRows = tableData.filter((row) =>
                              selectedIDs.has(row.rowpos),

                            );


                          }}
                          {...OrganiserData}
                        />
                        :
                        <>
                          <p className='cross mb' id="camerapop" style={{ display: "inline-flex", background: "tomato" }} onClick={closeifview} ><ion-icon name="close-outline"></ion-icon></p>
                          <p style={{ color: "#000", fontWeight: "800", border: "none" }}> <span style={{ color: "tomato" }}>TICKET / BOOTH / ADDON / REGISTRATION </span> </p>
                          <DataGrid
                            rows={allticketUniqueEvent}
                            columns={organisertblSibilingTickets}
                            pageSize={5}
                            getRowId={(row) => row.rowpos}
                            components={{
                              Toolbar: CustomToolbar,
                            }}
                            {...tableData}
                          />
                        </>

                    }

                  </>
                  : ""
              }
              {
                exhibitortable ?
                  <>
                    <DataGrid
                      rows={clienttableData}
                      columns={clientcolumn}
                      pageSize={5}
                      getRowId={(row) => row.chNumber}
                      components={{
                        Toolbar: CustomToolbar,
                      }}
                      // checkboxSelection
                      onSelectionModelChange={(ids) => {
                        const selectedIDs = new Set(ids);
                        const selectedRows = tableData.filter((row) =>
                          selectedIDs.has(row.chNumber),

                        );

                        // setSelectedRows(selectedRows);
                        // console.log(selectedRows)
                        // setfirstname(selectedRows.mcode)

                      }}
                      {...clienttableData}
                    />
                  </>
                  : ""
              }
              {
                eventTableshow ?

                  <DataGrid
                    className="animatingTop"
                    rows={EventTableData}
                    columns={EventTableCol}
                    pageSize={6}
                    getRowId={(row) => row.rowpos}
                    components={{
                      Toolbar: CustomToolbar,
                    }}
                  />
                  : ""
              }
              {
                museumTableshow ?

                  <DataGrid
                    className="animatingTop"
                    rows={filteredData}
                    columns={museumTableCol}
                    pageSize={6}

                    getRowId={(row) => row.rowpos}
                    components={{
                      Toolbar: CustomToolbar,
                    }}
                  />
                  : ""
              }
              {
                eventwiseTableshow ?

                  <DataGrid
                    className="animatingTop"
                    rows={EventwiseTableData}
                    columns={eventwisetblColumn}
                    pageSize={6}
                    getRowId={(row) => row.rowpos}
                    components={{
                      Toolbar: CustomToolbar,
                    }}
                  />
                  : ""
              }
            </div>
        }


      </div>


    </>
  )
}

export default Reports;