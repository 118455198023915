
import React, { useEffect, useState } from 'react'

import { ArrowBack } from '@material-ui/icons'
import { Link, useLocation, useNavigate } from "react-router-dom";
import QrScan from 'react-qr-reader'
import Alert from './alert/alert';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Button from '@mui/material/Button';
import { URL_GETORDER_BY_ORDERNUMBER, GETALL_MUSEUM, URL_GET_PROFILE_PHOTO, URL_REGISTRATION_BY_QR, URL_UPLOADIMAGE, URL_VERIFYTICKET } from '../api/constants';
import axios from 'axios';
import Print from './ReactToPrint';
import Camera from './Camera';
import ExternalDevice from './ExternalDevice';


function Qrcode() {
  const search = useLocation().search;
  const [qrscan, setQrscan] = useState('No result');
  const [alert, setalert] = useState(false)
  const [alertmsg, setalertmsg] = useState("")
  const [copied, setcopied] = useState(false)
  const [time, settime] = useState('')
  const [pcQuantity, setpcQuantity] = useState('')
  const [scaned, setscaned] = useState('')
  const [printPopup, setprintPopup] = useState(false)
  const [askcompany, setaskcompany] = useState("")
  const [printingArr, setprintingArr] = useState([])
  const [Printer, setprinter] = useState(false)
  const [capturedImage, setcapturedImage] = useState("")
  const [printwithImg, setprintwithImg] = useState(false)
  const [AskForImage, setAskForImage] = useState(false)
  const [profileImg, setprofileImg] = useState("")
  const [camerapopup, setcamerapopup] = useState(false)
  const [webcampopup, setwebcampopup] = useState(false)
  const [mcode, setmcode] = useState("")
  const [chnumber, setchnumber] = useState("")
  const [ticketid, setticketid] = useState("")
  const [found, setfound] = useState(false)

  const [userInput, setuserInput] = useState("")
  const [qtypoup, setqtypoup] = useState(false)
  const [MaxtckQty, setMaxtckQty] = useState(0)
  const [noremainig, setnoremainig] = useState(false)






  //verifyticket useState need to set 

  const [url, seturl] = useState(true)
  const [date, setdate] = useState("")
  const [success, setsuccess] = useState("success-box")
  const [succsshead, setsuccsshead] = useState("")
  const [ticketmsg, setticketmsg] = useState("")
  const [thischNumber, setthischNumber] = useState("")

  const [Address, setAddress] = useState("")
  const [onlineOffline, setonlineOffline] = useState("")
  const [EventType, setEventType] = useState("")

  const [thismcode, setthismcode] = useState("")
  const [eventname, seteventname] = useState("")
  const [orgname, setorgName] = useState("")
  const [startingdate, setstartingdate] = useState("")
  const [eventStartdate, seteventStartdate] = useState("")
  const [eventEnddate, seteventEnddate] = useState("")
  const [endingdate, setendingdate] = useState("")
  const [price, setprice] = useState("")
  const [tckqty, settckqty] = useState("")
  const [ticketname, setticketname] = useState("")

  const [Visitorname, setVisitorname] = useState("")
  // const [ticketnum,setticketid] = useState("")
  const [ticketnametyp, setticketnametyp] = useState("")


  //registration arry 

  const [registrationFound, setregistrationFound] = useState(false)
  const [registerArray, setregisterArray] = useState([])



  //verifyticket useState need to set



  const [visitName, setVisitName] = useState('')
  const [myurl, setmyurl] = useState("")

  const navigate = useNavigate()



  var myPrintingArray = []

  function delay(ms) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
        setalert(false)
      }, ms);
    });
  }


  const SubmitQuantity = async (e = null) => {
    console.log(ticketid + " " + chnumber);
    console.log(`Submitting with Visitorname: ${Visitorname}`);

    if (Visitorname) {
      setVisitName(Visitorname)
    }

    if (ticketid !== 'MUSEUM') {
      if (ticketid != '') {
        setqtypoup(true);


      }
    }
    else {
      setuserInput(pcQuantity)
    }



    if (!noremainig && userInput === "" && e && e.target.name !== "all") {
      setalert(true);
      setalertmsg("Please Enter the Quantity of Ticket to proceed OR Get All Tickets!");
      await delay(2000);
      return;


    }
    else if (userInput > MaxtckQty) {
      if (ticketid != 'MUSEUM') {
        setalert(true);
        setalertmsg(`You Can't Exceed Original ticket quantity more than ${MaxtckQty}!`);
        await delay(2000);
        return;

      }

    }

    // if (ticketid === 'MUSEUM') {
    //   setuserInput(0);
    // }

    console.log(userInput);
    console.log("submit called");
    console.log(ticketid + " " + chnumber);

    const data = JSON.stringify({
      "MCODE": mcode,
      "CHNumber": chnumber,
      "ticket": {
        "TicketId": ticketid,
        "MinCount": e ? (e.target.name === "customQty" ? userInput : (MaxtckQty !== null ? MaxtckQty : 0)) : (MaxtckQty !== null ? MaxtckQty : 0)
      }
    });

    const config = {
      method: 'post',
      url: URL_VERIFYTICKET,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    try {
      const response = await axios(config);
      console.log("verify ticket response");
      console.log(response);

      const notFound = !response.data.isSuccess;
      if (notFound) {
        setalert(true);
        setalertmsg(response.data.message);
        return;
      }

      const resData = response.data.data;
      setthischNumber(resData.chNumber);
      setthismcode(resData.mcode);
      setticketid(resData.ticket.ticketId);
      setticketnametyp(resData.ticketType);
      setticketname(resData.ticketName);
      seteventname(resData.eventName);
      setorgName(resData.orgName);
      setVisitorname(resData.vistorName);
      setstartingdate(resData.strExibitStartTime);
      seteventStartdate(resData.dtExibitStartDate);
      seteventEnddate(resData.dtExibitEndDate);
      setendingdate(resData.strExibitEndTime);
      settckqty(resData.pcQuantity);
      setprice(resData.ticket.price);
      setEventType(resData.eventRowpos);
      setonlineOffline(resData.remark);
      setAddress(resData.strSubType);
      console.log(response.data.message);
      setfound(true);

      setdate(resData.billDate);
      const result = response.data.isSuccess;

      if (!result) {
        setsuccsshead("OOPS!");
        setsuccess("error-box");
        setticketmsg(response.data.message);
      }
      else {
        setsuccess("success-box");
        setsuccsshead("Success!");
        setticketmsg(response.data.message);
      }

      seturl(false);
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    console.log(Visitorname)
    if (qrscan === 'No result') {
      console.log("Keep showing QR scanner");
    } else if (qrscan) {
      console.log(ticketid);
      if (ticketid !== 'MUSEUM') {
        setqtypoup(true);

      }
      console.log("popup true");

      const date = new Date();
      console.log(date);
      settime(date.toLocaleTimeString());

    }
  }, [qrscan, chnumber, Visitorname, mcode, ticketid]);

  useEffect(() => {

    console.log(Visitorname)
    if (ticketid !== '' && pcQuantity !== '' && chnumber !== '' && mcode !== '' && MaxtckQty !== '') {
      SubmitQuantity()
    }
  }, [ticketid, pcQuantity, Visitorname, chnumber, mcode, MaxtckQty]);

  useEffect(() => {
    console.log("Updated Visitorname:", Visitorname);
  }, [Visitorname]);



  const handleScan = (data) => {
    if (data) {
      console.log("Scanned data:", data);
      setmyurl(data);
      const u = data.replace("accex-api", "accex");

      if (u.includes("https")) {
        const GetValuesFromUrl = u.split("?")[1];
        const hiddenUrlvalue = atob(GetValuesFromUrl);
        const mytic = hiddenUrlvalue?.split("=");

        const Mcode = mytic[1]?.split("&")[0];
        const ChNumber = mytic[2]?.split("&")[0];
        const TicketiD = mytic[3]?.split("&")[0];

        setmcode(Mcode);
        setchnumber(ChNumber);

        if (TicketiD === "'MUSEUM'") {
          const ticket = TicketiD.replace(/'/g, "");
          setticketid(ticket);
        } else {
          setticketid(TicketiD);
        }

        const postData = JSON.stringify({
          CHNumber: ChNumber,
        });

        const config = {
          method: "post",
          url: URL_GETORDER_BY_ORDERNUMBER,
          headers: {
            "Content-Type": "application/json",
          },
          data: postData,
        };

        axios(config)
          .then(function (response) {
            console.log("Response data:", response.data);

            if (response.data.isSuccess) {
              const fetchedData = response.data.data[0];
              console.log("Fetched clientName:", fetchedData.clientName);
              if (fetchedData && fetchedData.clientName) {
                console.log(fetchedData.clientName)
                setVisitorname(fetchedData.clientName);
              } else {
                console.warn("Visitor name (clientName) is not available in the response.");
              }

              setprintingArr(fetchedData);



              const remainingQty = fetchedData.pcQuantity - fetchedData.strtotalPurchase;
              setMaxtckQty(remainingQty);

              if (remainingQty === 0 || fetchedData.strTicketID === "MUSEUM") {
                if (fetchedData.strTicketID === "MUSEUM") {
                  setpcQuantity(fetchedData.pcQuantity);
                  setticketid("MUSEUM");
                  SubmitQuantity();
                }
              }
            }
          })
          .catch(function (error) {
            console.error("Error:", error);
          });
      }
    }
  };






  const handleopenpo = () => {
    console.log(printingArr)
    setfound(false)
    setprintPopup(true)
    // now hit api to get details for the scanned QR values 

  }


  const handleregisteropenpo = () => {
    console.log(printingArr)
    setregistrationFound(false)
    setprintPopup(true)
    // now hit api to get details for the scanned QR values 

  }


  const closesetprintPopup = () => {
    setprintPopup(false)
    // setprintwithImg(true)
  }

  const handleIfChecked = (e) => {
    setprintwithImg(e.target.checked)
    console.log(e.target.checked)
    if (printwithImg) {
      setAskForImage(false)
    }
    else {
      setAskForImage(true)
    }
  }

  const handlePhotoPrint = (e) => {
    debugger;
    console.log(askcompany)
    console.log(e.target.files[0])
    setprofileImg(e.target.files[0])

  }

  const UploadSelectedPhoto = () => {
    debugger;
    console.log(printingArr)

    var FormData = require('form-data');
    var data = new FormData();
    data.append('File', profileImg);
    data.append('Type', 'ORDER');
    data.append('Id', printingArr.party);
    data.append('SubType', 'ProfilePhoto');

    var config = {
      method: 'post',
      url: URL_UPLOADIMAGE,
      headers: {
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        console.log(response.data.isSuccess);
        if (response.data.isSuccess == true) {
          setalert(true)
          setalertmsg('File Uploaded Sccessfully !')
          setAskForImage(false)
        }
        else {
          setalert(true)
          setalertmsg(response.data.message)
        }
      })
      .catch(function (error) {
        console.log(error);
      });

  }

  const captureimage = () => {
    setcamerapopup(true)

  }
  const capturewebcam = () => {
    setwebcampopup(true)

  }

  const handlereactPrint = async () => {

    debugger;
    console.log(printingArr)
    console.log(printwithImg)
    setAskForImage(false)


    var data = JSON.stringify({
      "Media_ID": printingArr.party
    });

    var config = {
      method: 'post',
      url: URL_GET_PROFILE_PHOTO,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    await axios(config)
      .then(function (response) {

        setcapturedImage(response.data.data[0].path)
        console.log(capturedImage)
        setprinter(true)
        setAskForImage(false)

      })
      .catch(function (error) {
        console.log(error);
      });
    setprinter(true)

  }

  const closecamera = () => {
    setcamerapopup(false)
  }

  const closewebcam = () => {
    setwebcampopup(false)
  }

  // setTimeout(() => {
  //   var date = new Date()
  //   console.log(date)
  //   settime(date.toLocaleTimeString())
  // }, 1000);


  //     const handleScan = data => {

  //         if (data) {


  //           console.log(data)
  //           setmyurl(data)
  //           var u = data.replace('accex-api','accex')

  //             setQrscan(u)

  //             // now populate details about this ticket

  //           //===================================================
  //           var baseurl = u;
  //           if(baseurl.includes('https')){
  //           //var baseurl = "https://dev-api.accex.saitec.in/verifyticket?TUNPREU9a2hnaHkwMDAwMDAwMDAwMDAwMDAxJkNITlVNQkVSPTYyMSZUSUNLRVRJRD1OSUVGVlFPTEFQRUNJV1lL";
  //          var  GetValuesFromUrl = baseurl.split("?")[1]
  //          // now decode this url  // now i have to decode these decodes         note :  btoa and atob 
  //          var hiddenUrlvalue  = atob(GetValuesFromUrl);

  //          var mytic = hiddenUrlvalue?.split("=")
  //          var Mcode = mytic[1]?.split("&")[0]
  //          var ChNumber = mytic[2]?.split("&")[0]


  //          if(mytic[3]=="'MUSEUM'"){
  //           setmcode(Mcode)
  // setchnumber(ChNumber)

  //   var ticket=mytic[3].replace(/\'/g, "");
  //   setticketid(ticket);
  // }

  //           // get the scanned ticket details 
  // // if(Mytic[3]!='MUSEUM')
  //         console.log(ChNumber)
  //         var data = JSON.stringify({

  //           "CHNumber": ChNumber

  //         });
  //         var config = {
  //           method: 'post',
  //           url: URL_GETORDER_BY_ORDERNUMBER,
  //           headers: { 
  //             'Content-Type': 'application/json'
  //           },
  //           data : data
  //         };
  //         axios(config)
  //         .then(function (response) {
  //           console.log(response.data.data)
  //           if(response.data.isSuccess==true){


  //           setprintingArr(response.data.data[0])
  //         setMaxtckQty(response.data.data[0].pcQuantity - response.data.data[0].strtotalPurchase)
  //         if(response.data.data[0].pcQuantity - response.data.data[0].strtotalPurchase == 0){
  //           setnoremainig(false)
  //           SubmitQuantity()
  //         }
  //       if(response.data.data[0].strTicketID=="MUSEUM"){
  //           SubmitQuantity()
  //         }
  //       }
  //         })
  //       }

  //   //=====================================================


  //         }
  //     }


  const handleError = err => {
    console.error(err)
    // setalert(true)
    // setalertmsg("Requested Device Not Found !  ")
  }

  const handlemarqueeClick = () => {
    window.location.href = qrscan
  }


  const getLink = () => {
    var a = window.location.href;
    return a
  }


  if (found) {


    return (
      <>
        {/* {

          } */}
        {alert ? <Alert className="top animating" msg={alertmsg} clas="qrticketmobile" /> : ""}

        <center>
          <button className='printticketafterverify' onClick={handleopenpo}>PRINT</button>
          <div id={success}>
            <div className="face">
              <div className="eye"></div>
              <div className="eye right"></div>
              <div className="mouth happy"></div>
            </div>
            <div className="shadow scale"></div>
            <div className="message"><h3 style={{ color: "#fff", fontSize: "30px" }} className="alert">{succsshead}</h3><p style={{ color: "#fff", fontSize: "24px" }}>{ticketmsg}</p></div>

          </div>
        </center>

        <div id={success}></div>
        <div className="ticketbox">


          <div className="ticket">
            <span className="airline">{eventname}</span>
            <span className="airline airlineslip">{ticketnametyp}</span>

            <div className="content">



              <div className="eventdetails verfifyTckt" >
                <div>
                  <p>Start Date : <span>{eventStartdate?.slice(0, 10)}</span> </p>
                  <p>Start Time : <span>{startingdate}</span> </p>
                  <p>Ticket ID :<span >{ticketid}</span> </p>
                  <p >Organisor Name :<span >{orgname}</span> </p>
                  <p>Visitor Name : <span>{visitName}</span></p>
                  <p>Ticket Type : <span>{ticketnametyp}</span></p>
                  <p>Ticket Name : <span>{ticketname}</span></p>
                  {/* <p>EVENT TYPE:<span>{onlineOffline}</span> </p> */}

                </div>
                <div className='marginFoeMobile'>
                  <p>End Date : <span>{eventEnddate?.slice(0, 10)}</span> </p>
                  <p>End Time : <span>{endingdate}</span> </p>

                  <p>Ticket Price : <span>{price}&nbsp;Rs</span> </p>
                  <p>Ticket Quantity : <span>{tckqty}&nbsp;</span> </p>
                  <p>Order number :<span>{thischNumber}</span> </p>

                  {/* <p> <span>{EventType}</span></p> */}
                  <p>Address: <span>{Address}</span></p>

                </div>
              </div>
            </div>

          </div>
        </div>



      </>
    )

  }


  if (registrationFound) {
    return (
      <>
        <button className='printticketafterverify' onClick={handleregisteropenpo}>PRINT</button>
        <br /><br /><br />
        {
          registerArray?.map((tickets, index) => {
            return (
              <>
                {alert ? <Alert className="top animating" msg={alertmsg} clas="qrticketmobile" /> : ""}
                <br /><br />
                <div className="" key={index}>
                  <center><h3 className="registratoinh3" >REGISTRATION DETAILS</h3></center>
                  <div className="eventdetails registrationbox">

                    <div>

                      {tickets.name === "" ? "" : <p><span>name:</span>{tickets.name}</p>}
                      {tickets.surname === "" ? "" : <p><span>surname:</span>{tickets.surname}</p>}
                      {tickets.gender === "" ? "" : <p><span>gender:</span>{tickets.gender}</p>}

                      {tickets.email === "" ? "" : <p><span>email:</span>{tickets.email}</p>}
                      {tickets.jobTitle === "" ? "" : <p><span>jobTitle:</span>{tickets.jobTitle}</p>}
                      {tickets.companyOrganisation === "" ? "" : <p><span>company / <br />Organisation:</span>{tickets.companyOrganisation}</p>}
                    </div>
                    <div>
                      {tickets.workAddress === "" ? "" : <p><span>workAddress:</span>{tickets.workAddress}</p>}
                      {tickets.workPhone === "" ? "" : <p><span>workPhone</span>:{tickets.workPhone}</p>}
                      {tickets.homeAddress === "" ? "" : <p><span>homeAddress:</span>{tickets.homeAddress}</p>}
                      {tickets.homePhone === "" ? "" : <p><span>homePhone:</span>{tickets.homePhone}</p>}
                      {tickets.website === "" ? "" : <p><span>website:</span>{tickets.website}</p>}
                      {tickets.cellPhone === "" ? "" : <p><span>cellPhone:</span>{tickets.cellPhone}</p>}
                      {tickets.blog === "" ? "" : <p><span>blog:</span>{tickets.blog}</p>}
                    </div>
                  </div>
                </div>
              </>
            )
          })
        }
      </>
    )
  }





  return (
    <div className='' style={{ background: "rgba(0,0,0,0.8)", height: "100vh" }}>
      {alert && <Alert className="top animating" msg={alertmsg} />}
      {/* {alert ?  <Alert style={{width:"200px !Important"}} className="top animating" msg={alertmsg}  />:""} */}
      <center><span><h3 className='animatingBottom' style={{ color: "rgb(55, 108, 255)" }}>QR Scanner</h3></span></center>
      <br />
      {/* <center><marquee style={{width:"50%",color:"crimson"}} id="mymarquee" onClick={handlemarqueeClick}>{qrscan === "No result" ? "":"Last Scanned : "}  {time}</marquee></center> */}
      <center>
        {
          qtypoup &&
          <div className=''>
            <div className='scannerQtyPopop animatingTop'>

              {/* <div className='d-flex'>
                
                </div> */}
              <label style={{ marginBottom: '30px' }}>Enter Ticket Quantity :</label>

              <input type='number' placeholder={`${MaxtckQty} Tickets Are Left`} maxLength={MaxtckQty} value={userInput} onChange={e => setuserInput(e.target.value)} />
              <div className='d-flex' style={{ justifyContent: 'space-between' }}>
                <Button style={{ marginTop: '10px', background: 'rgb(55, 108, 255)', color: '#111' }} className='btn' name='customQty' onClick={SubmitQuantity}>View Ticket </Button>
                <Button style={{ marginTop: '10px', background: 'rgb(44, 187, 44)', color: '#111' }} className='btn' name='all' onClick={SubmitQuantity}>All tickets</Button>
              </div>

            </div>
          </div>
        }


        <div id='myqrscnammerdiv' >
          <QrScan
            delay={300}
            onError={handleError}
            onScan={handleScan}
            style={{ height: 240, width: 320 }}
            className="animatingBottom"
          />
        </div>

      </center>

      <center>
        <a>
          <input type="text"
            style={{ fontSize: 18, color: "#fff", width: 320, height: 100, marginTop: 100, display: 'none' }}
            rowsMax={4}
            defaultValue={qrscan}
            value={qrscan}
            onChange={(e) => setQrscan(e.target.value)}
            className="animatingTop"

          /></a>
        <div className='copytoclipboard' >
          {/* <CopyToClipboard text={qrscan}  onCopy={()=>copied(true)}>
                <span className='copy'><ion-icon name="copy-outline"></ion-icon></span>
             </CopyToClipboard> */}
        </div>
        <br /> <br />
        {/* <a href={qrscan} className="goobutton animatingBottom" target="_blank" >go</a> */}
        {/* <button onClick={handleopenpo} display="none"></button> */}




      </center>

      {/* POPUP */}

      {
        printPopup ?
          <>

            <div
              className='popup-overlay'>
              <div className=' animatingTop qrpage-poup'>
                <p className='cross mb' onClick={closesetprintPopup}><ion-icon name="close-outline"></ion-icon></p>

                <div className='popupdata' style={{ padding: "20px" }}>
                  <div style={{ display: "flex", justifyContent: "space-between", width: "90%" }} >
                    <label >Company:</label>
                    <input type="text" placeholder='Enter Company' value={askcompany} onChange={(e) => setaskcompany(e.target.value)} />
                  </div>
                  <br />
                  <input type="checkbox" id="isPhoto" checked={printwithImg} onChange={(e) => handleIfChecked(e)} />
                  <label >Photo :</label>


                  {
                    printwithImg ?
                      <>
                        <input type="file" placeholder='Upload Photo' onChange={(e) => handlePhotoPrint(e)} />
                        <button style={{ background: "#016fe8", border: "2px solid #016fe8", color: "#fff", padding: "2px 6px", position: "absolute", right: "8%" }} onClick={UploadSelectedPhoto}>upload</button>
                        <div style={{ display: "flex", justifyContent: "space-between", width: "90%" }} >
                          {/* <p style={{display:'inline-block',transform:'scale(1.5)'}} onClick={captureimage}> <ion-icon name="camera-outline"></ion-icon></p> */}
                          <p style={{ display: 'inline-block', transform: 'scale(1.5)' }} onClick={capturewebcam}> <ion-icon name="videocam-outline"></ion-icon></p>
                        </div>
                      </>
                      : ""
                  }
                  {
                    Printer ? <Print pic={capturedImage} isImg={printwithImg} qr={printingArr.qrCodePath} name={printingArr.clientName} company={askcompany} ticket={printingArr.strTicketID} event={printingArr.eventRowpos} /> : ""
                  }
                </div>
                <div style={{ textAlign: 'center' }}>
                  {/* <Button onClick={printTicketPDF} style={{background:"red",border:"2px solid red#016fe8",color:"#fff",padding:"5px"}}>Print</Button>  */}
                  <Button style={{ background: 'green' }} variant="contained" color="primary" disabled={AskForImage} onClick={handlereactPrint}>Print Ticket</Button>
                </div>
              </div>
            </div>
          </>

          : ""
      }
      {/* camera popup */}
      {
        camerapopup ?
          <>
            <div className='userpopup animatingTop'>
              <p className='cross mb' id="camerapop" style={{ display: "inline-flex" }} onClick={closecamera}  ><ion-icon name="close-outline"></ion-icon></p>

              <div className='popupdata' style={{ padding: "20px" }}>

                <Camera upload={setAskForImage} mcode={printingArr.party} />

              </div>
            </div>
          </>
          : ""
      }
      {/* camera popup */}
      {
        webcampopup ?
          <>
            <div className='qrpage-poup animatingTop'>
              <p className='cross mb' id="camerapop" style={{ display: "inline-flex" }} onClick={closewebcam}  ><ion-icon name="close-outline"></ion-icon></p>

              <div className='popupdata' style={{ padding: "20px" }}>
                <ExternalDevice upload={setAskForImage} mcode={printingArr.party} />
              </div>
            </div>
          </>
          : ""
      }

    </div>
  );
}


export default Qrcode;

